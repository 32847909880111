<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-app-bar app density="compact" clipped-left color="white" elevation="1">
      <v-app-bar-title><a href="https://xcelaudit.com">
          <v-img src="@/assets/New_XcelAuditLogo.png" contain height="70px" max-width="150px"></v-img></a>
      </v-app-bar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <v-card class="overflow-auto" elevation="0">
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="12">
              <v-card elevation="0">
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="12" md="8">
                    <v-card :height="windowHeight" color="primary" elevation="0">
                      <v-container>
                        <v-row align="center" justify="center" :style="{ height: windowHeight + 'px' }">
                          <v-col>
                            <div align="center" style="
                                font-size: 35px;
                                font-weight: bold;
                                line-height: 40px;
                              ">
                              {{ current_header }}
                            </div>
                            <div align="center" class="my-6">
                              <v-img max-width="180px" v-if="
                                current_header == 'Step by step work flow'
                              " src="@/assets/organization.gif"></v-img>
                              <v-img max-width="180px" v-if="
                                current_header ==
                                'Step by step across all segments from pre planning till closure'
                              " src="@/assets/checklist.gif"></v-img>
                              <v-img max-width="180px" v-if="
                                current_header ==
                                'Templates and work papers for structured work environment'
                              " src="@/assets/worklist.gif"></v-img>
                              <v-img max-width="180px" v-if="
                                current_header ==
                                'Seemless integration of queries and resolutions'
                              " src="@/assets/opinion.gif"></v-img>
                              <v-img max-width="180px" v-if="
                                current_header ==
                                'Real time updation of all legislative requirements.'
                              " src="@/assets/question.gif"></v-img>
                              <div class="text-white py-6" style="font-size: 18px">
                                Make your audit more efficient and perfect and
                                in line with the requirements of all the
                                regulators
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" align="center">
                    <v-card class="mt-10 py-8" max-width="400" elevation="0">
                      <v-card-title class="pb-4 pt-1 mt-0">
                        <v-row class="text-center">
                          <v-col cols="12" class="py-0" align="center">
                            <a href="https://xcelaudit.com">
                              <v-img src="@/assets/New_XcelAuditLogo.png" contain height="70px"
                                width="170px"></v-img></a>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <div v-if="currentTitle != 'Change Temporary Password'" class="mt-5">
                              <h3 class="dark_text-primary font-weight-regular">
                                {{ currentTitle }}
                              </h3>
                            </div>
                            <div v-else class="mt-5">
                              <h3 class="dark_text-primary font-weight-regular">
                                {{ currentTitle }}
                              </h3>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-window v-model="step" class="px-3">
                          <v-window-item :value="1">
                            <v-card-text>
                              <v-form ref="form" lazy-validation class="mt-2">
                                <v-text-field color="primary" variant="outlined" density="compact"
                                  class="field_height field_label_size field_font" label="Email ID *" :rules="[
                                    (v) => !!v || 'Email ID is required',
                                  ]" v-model="Login.EmailID">
                                  <template v-slot:prepend-inner>
                                    <v-icon color="primary">mdi-account</v-icon>
                                  </template>
                                </v-text-field>
                                <v-text-field variant="outlined" density="compact" label="Password *" color="primary"
                                  :type="isPwd ? 'text' : 'password'" @click:append-inner="isPwd = !isPwd" required
                                  class="field_height field_label_size field_font" :rules="[
                                    (v) => !!v || 'Password is required',
                                  ]" v-model="Login.Password">
                                  <template v-slot:prepend-inner>
                                    <v-icon color="primary">mdi-lock</v-icon>
                                  </template>
                                  <template v-slot:append-inner>
                                    <v-icon color="primary" @click="isPwd = !isPwd">{{
                                      isPwd ? "mdi-eye" : "mdi-eye-off"
                                    }}</v-icon>
                                  </template>
                                </v-text-field>
                              </v-form>
                            </v-card-text>
                            <v-card-actions class="py-0 mt-n4">
                              <v-checkbox density="compact" class="my-0 py-0 ml-1 FontSize" v-model="rememberme"
                                color="primary" label="Remember Me" :disabled="Login.EmailID === '' && Login.Password === ''
                                  " />
                              <v-spacer></v-spacer>
                              <v-btn size="small" text class="FontSize mb-4" color="primary text-capitalize fontStyle"
                                @click="step = 3">Forgot
                                Password ?</v-btn>
                            </v-card-actions>
                            <v-card-actions class="pt-0 pb-2">
                              <v-btn block variant="flat" :loading="loading" tile color="primary"
                                @click="ValidateSignUpForm()">Login</v-btn>
                            </v-card-actions>
                            <!-- <v-btn
                              block
                              variant="flat"
                              tile
                              color="primary"
                              @click="logoutMethod()"
                              >Logout</v-btn
                            > -->
                          </v-window-item>
                          <v-window-item :value="2">
                            <v-card-text>
                              <v-form ref="formCP" lazy-validation>
                                <v-text-field variant="outlined" density="compact" color="primary"
                                  class="field_height field_label_size field_font" label="Enter New Password *"
                                  :type="isPwdNew ? 'text' : 'password'" @click:append-inner="isPwdNew = !isPwdNew"
                                  required :append-inner-icon="isPwdNew ? 'mdi-eye' : 'mdi-eye-off'
                                    " prepend-inner-icon="mdi-lock" :rules="[
                                    (v) => !!v || 'Password is required',
                                  ]" v-model="Password.NewPassword"></v-text-field>
                                <v-text-field class="field_height field_label_size field_font" variant="outlined"
                                  density="compact" label="Confirm New Password *" color="primary"
                                  :type="isPwdConfirm ? 'text' : 'password'" @click:append-inner="
                                    isPwdConfirm = !isPwdConfirm
                                    " required :append-inner-icon="isPwdConfirm ? 'mdi-eye' : 'mdi-eye-off'
                                    " prepend-inner-icon="mdi-lock" :rules="[
                                    (v) => !!v || 'Password is required',
                                  ]" v-model="Password.ConfirmPassword"></v-text-field>
                              </v-form>
                            </v-card-text>
                            <v-card-actions class="pt-0 pb-8 px-4">
                              <v-btn block variant="flat" :loading="loading" tile color="primary"
                                @click="ValidateConfirmPasswordForm()">Login</v-btn>
                            </v-card-actions>
                          </v-window-item>
                          <v-window-item :value="3">
                            <v-card-text>
                              <v-form ref="formOTP">
                                <div class="text-center">
                                  Please Enter The Registered Email
                                </div>
                                <v-text-field density="compact" variant="outlined" label="Email" v-model="OTPEmail"
                                  color="primary" :rules="[
                                    (v) => !!v || 'Email ID is required',
                                  ]" class="mt-4 field_font field_label_size field_height">
                                </v-text-field>
                                <div class="FontSize text-center">
                                  *Please enter the e-mail used for XcelAudit
                                  login/registration
                                </div>
                              </v-form>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn text color="primary fontStyle" size="small" @click="step = 1">Back To Sign
                                In</v-btn>
                              <v-spacer></v-spacer>
                              <v-btn tile color="primary" size="small" @click="ValidateOTP()">Next</v-btn>
                            </v-card-actions>
                          </v-window-item>
                          <v-window-item :value="4">
                            <v-card-text>
                              <v-form ref="formConfirmOTP">
                                <div class="text-center">
                                  Enter the XcelAudit Activation Code
                                </div>
                                <v-text-field density="compact" variant="outlined" label="Code *" v-model="ConfirmOTP"
                                  color="primary" :rules="[(v) => !!v || 'Code is required']"
                                  class="mt-4 field_font field_label_size field_height">
                                </v-text-field>
                                <v-text-field density="compact" variant="outlined" label="Enter New Password *"
                                  color="primary" v-model="ConfirmNewPassword" :rules="[
                                    (v) => !!v || 'Password is required',
                                  ]" class="field_height field_label_size field_font"
                                  :type="isPwdConfirmCode ? 'text' : 'password'" @click:append-inner="
                                    isPwdConfirmCode = !isPwdConfirmCode
                                    " required :append-inner-icon="isPwdConfirmCode ? 'mdi-eye' : 'mdi-eye-off'
                                    " prepend-inner-icon="mdi-lock">
                                </v-text-field>
                                <v-text-field density="compact" variant="outlined" label="Re-enter New Password *"
                                  color="primary" v-model="ReenterNewPassword" :rules="[
                                    (v) => !!v || 'Password is required',
                                  ]" class="field_height field_label_size field_font"
                                  :type="isPwdConfirmCode ? 'text' : 'password'" @click:append-inner="
                                    isPwdConfirmCode = !isPwdConfirmCode
                                    " required :append-inner-icon="isPwdConfirmCode ? 'mdi-eye' : 'mdi-eye-off'
                                    " prepend-inner-icon="mdi-lock">
                                </v-text-field>
                                <div class="FontSize text-center">
                                  Enter the XcelAudit Activation Code sent to
                                  your registered Email.
                                </div>
                              </v-form>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn text color="primary" size="small" @click="step = 1">Back To Sign In</v-btn>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" size="small" @click="ValidateEnteredOTP()">Next</v-btn>
                            </v-card-actions>
                          </v-window-item>
                        </v-window>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-main>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import {
  signIn,
  signOut,
  resetPassword,
  confirmResetPassword,
  confirmSignIn,
} from "aws-amplify/auth";

export default {
  data: () => ({
    isFormValid: false,
    step: 1,
    height: 0,
    windowHeight: 0,

    OTPEmail: "",
    ConfirmOTP: "",
    current_header: "",
    ConfirmNewPassword: "",
    ReenterNewPassword: "",

    Login: {
      EmailID: "",
      Password: "",
    },
    isPwd: false,
    loading: false,
    isPwdNew: false,
    rememberme: false,
    themeLoaded: false,
    isPwdConfirm: false,
    isPwdConfirmCode: false,
    domain: null,
    Password: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    theme_details: {},
    SnackBarComponent: {},

    headerArrays: [
      "Step by step across all segments from pre planning till closure",
      "Templates and work papers for structured work environment",
      "Real time updation of all legislative requirements.",
      "Seemless integration of queries and resolutions",
      "Step by step work flow",
    ],
  }),
  components: {
    Snackbar,
  },

  watch: {
    rememberme(val) {
      if (val == true) {
        localStorage.setItem("currentuseremail", JSON.stringify(this.Login));
      } else if (val == false) {
        delete localStorage.currentuseremail;
      }
    },
  },
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Bms-Sign In";
        case 2:
          return "Change Temporary Password";
        case 3:
          return "Forgot Password ?";
        case 4:
          return "Forgot Password ?";
        default:
          return "Account created";
      }
    },
  },
  async mounted() {
    this.CheckRememberMe();
    this.windowHeight = window.innerHeight - 83;
    this.runHeaderMethod();
  },
  methods: {
    async ValidateSignUpForm() {
      let isValid = this.$refs.form.validate();
      try {
        const result = await isValid;
        if (result.valid) {
          await this.SignIn();
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "error",
            Top: true,
            SnackbarText: "Fields marked with asterisks (*) are mandatory",
          };
        }
      } catch (error) {
        console.error("error inn ValidateSignUpForm", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "error",
          Top: true,
          SnackbarText: error,
        };
      }
    },

    async SignIn() {
      try {
        this.loading = true;
        const { nextStep } = await signIn({
          username: this.Login.EmailID.toLowerCase().trim(),
          password: this.Login.Password.trim(),
        });

        // console.log("User signed in successfully", nextStep);
        if (
          nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
        ) {
          this.step = 2;
          this.loading = false;
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID.toLowerCase());
        } else {
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID.toLowerCase());
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Successful",
          };
          this.ActivateMethod();
        }
      } catch (error) {
        console.error("Error signing in user", error.message);
        this.loading = false;
        if (error.message === "There is already a signed in user.") {
          await this.logoutMethod(this.Login.EmailID.toLowerCase());
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "error",
            Top: true,
            SnackbarText: error.message,
          };
        }
      }
    },

    ActivateMethod() {
      this.loading = true;
      setTimeout(() => this.$router.push("/Customers"), 500);
      this.loading = false;
    },

    runHeaderMethod() {
      this.count = 0;
      this.current_header = this.headerArrays[this.count];
      setInterval(() => {
        if (this.count == this.headerArrays.length - 1) {
          this.count = 0;
        } else {
          this.count += 1;
        }
        this.current_header = this.headerArrays[this.count];
      }, 5000);
    },
    CheckRememberMe() {
      if (localStorage.getItem("currentuseremail") != null) {
        this.Login = JSON.parse(localStorage.getItem("currentuseremail"));
        this.rememberme = true;
      }
    },

    async ConfirmOTPMethod() {
      try {
        await confirmResetPassword({
          username: this.OTPEmail,
          confirmationCode: this.ConfirmOTP,
          newPassword: this.ConfirmNewPassword,
        }).then(() => {
          this.$refs.formConfirmOTP.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Password Successfully Changed ",
          };
          this.step = 1;
        });
      } catch (err) {
        // console.log("error in ConfirmOTPMethod", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "error",
          Top: true,
          SnackbarText: err.message,
        };
      }
    },
    async ValidateEnteredOTP() {
      let isValid = this.$refs.formConfirmOTP.validate();
      try {
        const result = await isValid;
        // console.log(result.valid);
        if (result.valid) {
          if (this.ReenterNewPassword == this.ConfirmNewPassword) {
            await this.ConfirmOTPMethod();
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "error",
              Top: true,
              SnackbarText: "Password Does not match",
            };
          }
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "error",
            Top: true,
            SnackbarText: "Please Enter The Code Sent To Registered Email",
          };
        }
      } catch (error) {
        console.error("error in ValidateEnteredOTP", error);
      }
    },

    async ValidateOTP() {
      let isValid = this.$refs.formOTP.validate();
      try {
        const result = await isValid;
        // console.log(result.valid);
        if (result.valid) {
          await this.SendOTPEmail();
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "error",
            Top: true,
            SnackbarText: "Please Enter Registered Email",
          };
        }
      } catch (error) {
        console.error(error); // Logs any error if the promise gets rejected
      }
    },
    async SendOTPEmail() {
      try {
        const output = await resetPassword({ username: this.OTPEmail });
        // console.log("output", output);
        this.handleResetPasswordNextSteps(output);
      } catch (err) {
        // console.log("err in SendOTPEmail", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "error",
          Top: true,
          SnackbarText: err.message,
        };
      }
    },
    handleResetPasswordNextSteps(output) {
      const { nextStep } = output;
      switch (nextStep.resetPasswordStep) {
        case "CONFIRM_RESET_PASSWORD_WITH_CODE":
          const codeDeliveryDetails = nextStep.codeDeliveryDetails;
          // console.log(
          //   `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
          // );

          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "OTP Sent To Registered Email",
          };
          this.step = 4;

          // Collect the confirmation code from the user and pass to confirmResetPassword.
          break;
        case "DONE":
          // console.log("Successfully reset password.");
          break;
      }
    },

    async ConfirmPassword() {
      try {
        this.loading = true;
        // to send the answer of the custom challenge
        const result = await confirmSignIn({
          challengeResponse: this.Password.ConfirmPassword,
        });
        if (result.nextStep.signInStep === "DONE") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Successful",
          };
          this.$refs.formCP.reset();
          this.ActivateMethod();
        }
        this.loading = false;
        // console.log("result of ConfirmPassword,", result);
      } catch (err) {
        // console.log("err in ConfirmPassword", err);
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "error",
          Top: true,
          SnackbarText: err.message,
        };
      }
    },
    async ValidateConfirmPasswordForm() {
      let isValid = this.$refs.formCP.validate();
      try {
        const result = await isValid;
        if (result.valid) {
          if (this.Password.NewPassword == this.Password.ConfirmPassword) {
            await this.ConfirmPassword();
          } else {
            this.$refs.form.reset();
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "error",
              Top: true,
              SnackbarText: "Password does not match",
            };
          }
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "error",
            Top: true,
            SnackbarText: "Fields marked with asterisks (*) are mandatory",
          };
        }
        // console.log(result.valid); // Logs the valid property from the resolved promise
      } catch (error) {
        // console.error("error in ValidateConfirmPasswordForm", error); // Logs any error if the promise gets rejected
      }
    },

    async logoutMethod(Email) {
      try {
        const user = await signOut({
          username: Email,
        });
        // console.log("User signed in successfully", user);
        // this.SnackBarComponent = {
        //   SnackbarVmodel: true,
        //   SnackbarColor: "green",
        //   Top: true,
        //   SnackbarText: "Logout success",
        // };
        this.ValidateSignUpForm();
        return user;
      } catch (error) {
        console.error("Error signing in user", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "error",
          Top: true,
          SnackbarText: error.message,
        };
        throw error;
      }
    },

    // this.$router.push("/LandingPage");
  },
};
</script>

<style scoped>
.v-application .rounded-b1-x1 {
  border-bottom-left-radius: 300px !important;
}

.v-application .rounded-br-x1 {
  border-bottom-right-radius: 300px !important;
}
</style>
