<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<style>
.maxWidthXSmall {
  max-width: 120px !important;
}
.fontSizeSmall {
  font-size: 10px !important;
}
.fontSize12px {
  font-size: 12px !important;
}
.fontSize14px {
  font-size: 14px !important;
}
.field_height .v-field {
  min-height: 12px !important;
  max-height: 32px !important;

  display: flex !important;
  align-items: center !important;
}

/* .feild_width1 .v-field {
  width: 10px !important;
} */

.field_label_size.v-input .v-label {
  font-size: 12px !important;
  opacity: 1 !important;
}

.FontSize .v-field__input {
  font-size: 12px !important;
}

.searchFieldWidth .v-field {
  width: 1px !important;
}

.v-select
  .v-input__control
  .v-input__slot
  .v-select__slot
  .v-select__selections {
  padding: 0 !important;
  min-height: 0 !important;
}

.v-select-list .v-list-item {
  min-height: 0 !important;
}

.v-select-list .v-list-item .v-list-item__content {
  padding: 14px 0 !important;
}

.image-upload-input {
  display: none;
  z-index: -9999;
}
.cursorPointer {
  cursor: pointer !important;
}
.hover_SelectedItem:hover {
  background-color: #96bfe9 !important;
}
.SharpBorderRadius {
  border-radius: 0px !important;
}
</style>
