<template>
  <v-snackbar
    :color="SnackBarComponent.SnackbarColor"
    v-model="SnackBarComponent.SnackbarVmodel"
    location="top"
  >
    <div class="text-center">{{ SnackBarComponent.SnackbarText }}</div>
  </v-snackbar>
</template>
<script>
export default {
  props: {
    SnackBarComponent: Object,
  },
  data: () => ({
    Top: true,
  }),
};
</script>
